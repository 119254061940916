import React from "react"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../../components/layout/layout"
import InnerWrapper from "../../components/layout/innerWrapper"
import SEO from "../../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faInstagram,
  faTwitter,
  faFacebook,
  faPinterest,
  faYoutube,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"
import { Link } from "gatsby"
import ConnectSidebar from "../../components/layout/connect-sidebar"

const SocialPage = () => {
  return (
    <Layout className="main inner-page-with-banner">
      <SEO
        title={`Walpole Outdoors - Connect with Us`}
        description={`The standard all other fence and outdoor structures are measured by.`}
      />

      {/* CONTENT GOES HERE */}
      <InnerWrapper>
        <div className="page-hero"></div>

        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/">Connect with Us</Breadcrumb.Item>
          <Breadcrumb.Item href="/connect/social">
            Connect on Social
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row className="generalcontent">
          <Col md={3}>
            <ConnectSidebar></ConnectSidebar>
          </Col>
          <Col md={9}>
            <h1 className="generalheader">Connect on Social</h1>
            <p>
              Our social media channels are a great way to learn about Walpole
              Outdoors, our products, events, happenings, and more! Follow us
              and discover how to Live Outside Expectations.
            </p>
            <hr />

            <div className="social-container">
              <a
                href="https://www.instagram.com/walpoleoutdoors/"
                className="childcont"
                target="_blank"
                rel="noreferrer"
              >
                <div className="childitem" id="wrapper">
                  <div className="iconleft">
                    <a
                      href="https://www.instagram.com/walpoleoutdoors/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </div>
                  <div className="textright">
                    <h2 className="socialheader">Instagram</h2>
                  </div>
                </div>
              </a>
              <a
                href="https://www.facebook.com/WalpoleOutdoors/"
                className="childcont"
                target="_blank"
                rel="noreferrer"
              >
                <div className="childitem" id="wrapper">
                  <div className="iconleft">
                    <a
                      href="https://www.facebook.com/WalpoleOutdoors/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>
                  </div>
                  <div className="textright">
                    <h2 className="socialheader">Facebook</h2>
                  </div>
                </div>
              </a>
              <a
                href="https://www.pinterest.com/walpoleoutdoors/"
                className="childcont"
                target="_blank"
                rel="noreferrer"
              >
                <div className="childitem" id="wrapper">
                  <div className="iconleft">
                    <a
                      href="https://www.pinterest.com/walpoleoutdoors/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faPinterest} />
                    </a>
                  </div>
                  <div className="textright">
                    <h2 className="socialheader">Pinterest</h2>
                  </div>
                </div>
              </a>
              <a
                href="https://www.linkedin.com/company/walpoleoutdoors/"
                className="childcont"
                target="_blank"
                rel="noreferrer"
              >
                <div className="childitem" id="wrapper">
                  <div className="iconleft">
                    <a
                      href="https://www.linkedin.com/company/walpoleoutdoors/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </div>
                  <div className="textright">
                    <h2 className="socialheader">LinkedIn</h2>
                  </div>
                </div>
              </a>
              <a
                href="https://www.youtube.com/user/WalpoleOutdoors"
                className="childcont"
                target="_blank"
                rel="noreferrer"
              >
                <div className="childitem" id="wrapper">
                  <div className="iconleft">
                    <a
                      href="https://www.youtube.com/user/WalpoleOutdoors"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                  </div>
                  <div className="textright">
                    <h2 className="socialheader">Youtube</h2>
                  </div>
                </div>
              </a>
              <a
                href="https://twitter.com/WalpoleOutdoors"
                className="childcont"
                target="_blank"
                rel="noreferrer"
              >
                <div className="childitem" id="wrapper">
                  <div className="iconleft">
                    <a
                      href="https://twitter.com/WalpoleOutdoors"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </div>
                  <div className="textright">
                    <h2 className="socialheader">Twitter</h2>
                  </div>
                </div>
              </a>
            </div>
          </Col>
        </Row>
      </InnerWrapper>
    </Layout>
  )
}

export default SocialPage
